/** DOCUMENTATION **/

.documentation
{
  margin-top: 0px;
  margin-bottom: 0px;
  text-rendering: optimizelegibility;
}

.documentation h1
{
  text-align: center;
  font-size: 2.5em;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: 600;
  color: #444;
}

.documentation h2
{
  font-size: 1.8em;
  margin-bottom: 20px;
  margin-top: 30px;
  font-weight: 400;
  color: #444;
}

.documentation h3
{
  font-size: 1.3em;
  margin-bottom: 10px;
  margin-top: 30px;
  text-transform: none;
  font-weight: 400;
}

.documentation h4
{
  font-size: 1.2em;
}

.documentation h5
{
  font-size: 1.15em;
}

.documentation ul
{
  margin-bottom: 25px;
  margin-top: -10px;
}

.documentation table
{
  margin-bottom: 40px;
}

.documentation-language-selector
{
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.documentation-language-selector h3
{
  text-transform: none;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
  color: #444;
}

.documentation-search-box
{
  padding: 0px;
  margin: 0px;
  text-align: right;
}

.documentation-search-box h3
{
  text-transform: none;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
  color: #444;
}

.documentation blockquote > p
{
  font-size: 1.1em;
  font-weight: 300;
}




.doctree.jstree-proton .jstree-clicked
{
  background: #fafafa;
  color: inherit;
  border-radius: 0px;
  box-shadow: none;
  font-weight: inherit;
}

.doctree.jstree-proton .jstree-anchor
{
  font-size: 16px;
}

.doctree.jstree-proton
{
  font-family: inherit;
}


/**** DOCUMENTATION *****/

.documentation-image
{
  text-align: center;
  margin-bottom: 20px;
}

.documentation-image img.docimage
{
  max-width: 100%;
  height: auto;
  /*display: block;*/
}

.documentation-sidebar
{
  margin: 0px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;
  //background-color: #f8f8f8;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding-right: 0px;

  margin-left: 0px;
}

.documentation-sidebar .jstree-node.active > a.jstree-anchor
{
  font-weight: bold;
  color: #0074ad;
}

.documentation-sidebar .jstree-node
{
}

.documentation-sidebar .jstree-node > a.jstree-anchor
{
  margin: inherit;
  height: 28px;
  line-height: 28px;
  padding: 0px;
  margin-left: 0px;
  color: #333;
}

.documentation-sidebar .jstree-icon
{
  height: 28px !important;
  line-height: 28px !important;
  margin-top: 2px;
  margin-left: 5px;
}

.documentation-sidebar .jstree-node a
{
  font-size: 24px;
}

.documentation-sidebar li.jstree-node > a
{
  text-align: left;
}

.documentation-sidebar li.jstree-node > i.jstree-icon.jstree-ocl
{
}

.documentation p
{
  color: #3b4750;
  margin-bottom: 25px;
  //font-size: 1.05em;
  font-size: 1.1em;
}

.documentation a.named-link
{
  scroll-margin-top: 100px;
}

.documentation a.named-link > i
{
  margin-left: 0px;
  margin-right: 10px;
  font-size: 20px;
}

.documentation h2[id],
.documentation h3[id],
.documentation h4[id],
.documentation h5[id]
{
  padding-top: 160px;
  margin-top: -140px;
}

.documentation h5
{
  text-transform: none;
  font-size: 1.35em;
  line-height: 26px;
}

.documentation h6
{
  text-transform: none;
  font-size: 1.25em;
  line-height: 23px;
  margin-top: 30px;
}

.documentation .documentation-image-ex img
{
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
}

//.documentation code
//{
//  color: #8811aa;
//  //font-weight: 800;
//  //color: #0074ad;
//
//  font-weight: 600;
//  //color: black;
//  background: #eaeaea;
//  padding: 4px;
//}

//.documentation p > code,
//.documentation pre > code
//{
//  color: #8811aa;
//  //font-weight: 800;
//  //color: #0074ad;
//
//  font-weight: 400;
//  //color: black;
//  background: unset;
//  padding: unset;
//
//}

pre[class*=language-]
{
  padding-top: 0px !important;
  border: 1px #ccc solid !important;
}

.documentation ul li,
.documentation ul li p,
.documentation ol li,
.documentation ol li p
{
  font-size: 1.1em;
  //font-size: 18px;
  color: #3b4750;
}

.documentation pre > ol > li,
.documentation pre > ol > li > p
{
  font-size: inherit;
}

.documentation ol > li,
.documentation ul > li
{
  margin-bottom: 10px;
}

.documentation ol.linenums > li,
.documentation ul.linenums > li
{
  margin-bottom: 0px !important;
}


.documentation table tr td
{
  font-weight: normal;
  font-size: 16px;
}

.documentation a
{
  color: #226baa;
  font-weight: 500;
  text-decoration: underline;
}

.documentation .alert-comingsoon
{
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  font-size: 18px;
}










.row.documentation table.table > thead > tr > th:first-child
{
}

.row.documentation table.table > thead > tr > th:not(:first-child)
{
  width: 10%;
}

.row.documentation table.table > tbody > tr > td i.fa.fa-check
{
  color: green;
}

.row.documentation table.table > tbody > tr > td i.fa.fa-times
{
  color: red;
}

.row.documentation table.table > thead > tr > th.table-description
{
  width: 33%;
}





.documentation-image img.docimage
{

}

@media (min-width: 992px) {

  .documentation-image img.docimage.docimage-100 {
    max-width: 100px;
  }

  .documentation-image img.docimage.docimage-200 {
    max-width: 200px;
  }

  .documentation-image img.docimage.docimage-300 {
    max-width: 300px;
  }

  .documentation-image img.docimage.docimage-400 {
    max-width: 400px;
  }

  .documentation-image img.docimage.docimage-450 {
    max-width: 450px;
  }

  .documentation-image img.docimage.docimage-500 {
    max-width: 500px;
  }

  .documentation-image img.docimage.docimage-600 {
    max-width: 600px;
  }

  .documentation-image img.docimage.docimage-700 {
    max-width: 700px;
  }

  .documentation-image img.docimage.docimage-800 {
    max-width: 800px;
  }

  .documentation-image img.docimage.docimage-900 {
    max-width: 900px;
  }

  .documentation-image img.docimage.docimage-1000 {
    max-width: 1000px;
  }

  .documentation-image img.docimage.docimage-320 {
    max-width: 320px;
  }

  .documentation-image img.docimage.docimage-650 {
    max-width: 650px;
  }

  .documentation-image img.docimage.docimage-250 {
    max-width: 250px;
  }

  .documentation-image img.docimage.docimage-256 {
    max-width: 256px;
  }

  .documentation-image img.docimage.docimage-480 {
    max-width: 480px;
  }

  .documentation-image img.docimage.docimage-1200 {
    max-width: 1200px;
  }

}






.jstree-node.book.jstree-open
{
  margin-bottom: 10px;
}

.doctree li.book > a
{
  font-size: 1.0em !important;
  //height: 36px !important;
}

.documentation-sidebar .jstree-node>a.jstree-anchor
{
  margin-bottom: 0px;
}

.doctop
{

}

.doctop .docside
{
  padding-right: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 5px;
  border-right: 1px #e2e5e9 solid;
}

.doctop .docbody
{
  background-color: #f2f5f9;
}

.doctop .docpaper
{
  padding: 30px;
  background-color: white;
  border: 1px #e2e5e9 solid;
  margin: 30px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 8px;
}

.subheader-holder ol.breadcrumb
{
  background-color: transparent;
  padding-bottom: 0px;
}

.docbody .admonitionblock
{
  margin: 1.4rem 0 0;
  margin-top: 50px;
}

.docbody .admonitionblock > table
{
  border-collapse: collapse;
  table-layout: fixed;
  position: relative;
  width: 100%;
}

.docbody .admonitionblock .icon
{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.9em;
  padding: 0.4rem;
  height: 1.6rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0.45rem;
  -webkit-transform: translate(-0.5rem,-50%);
  transform: translate(-0.5rem,-50%);
  font-family: Roboto,sans-serif!important;
}

.docbody .admonitionblock.important .icon
{
  background-color: #b00020;
  color: #fff;
}

.docbody .admonitionblock.note .icon
{
  background-color: #4a71c4;
  color: #fff;
}

.docbody .admonitionblock .icon i
{
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  margin-right: 5px;
}

.docbody .admonitionblock td.content
{
  padding: 1rem 1rem 0.75rem;
  background: #e8ecf3;
  width: 100%;
}


.breadcrumbs ol.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #ccc;
  padding-top: 8px;
}


// formal proton overrides for expand/collapse icons

.documentation-sidebar .jstree-icon.jstree-ocl
{
  background-position: unset !important;
  background-size: unset !important;
  background-image: unset !important;
}
.documentation-sidebar i.jstree-icon
{
  font-size: 0.7em;
  font-weight: 100;
}
.documentation-sidebar li.jstree-open > i.jstree-icon.jstree-ocl::before
{
  display: inline-block !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font: var(--fa-font-solid) !important;
  //content: "\f0d7" !important;
  content: "\f107" !important;
  color: #777;
}

.documentation-sidebar li.jstree-closed > i.jstree-icon.jstree-ocl::before
{
  display: inline-block !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font: var(--fa-font-solid) !important;
  //content: "\f0da" !important;
  content: "\f105" !important;
  color: #777;
}

.subheader .subheader-holder .version
{
  display: flex;
  justify-content: flex-start;
  //width: 200px;
  height: 100%;
  align-items: center;
  //border-right: 1px #ccc solid;
}

.subheader .subheader-holder .books
{
  flex: 1;
}

//.subheader .subheader-holder .version .btn-group button.btn.btn-outline-secondary:hover,
//.subheader .subheader-holder .book .btn-group button.btn.btn-outline-secondary:hover
//{
//  background-color: #e7e7e7;
//}
//
//.subheader .subheader-holder .version .btn-group button.btn.btn-outline-secondary,
//.subheader .subheader-holder .book .btn-group button.btn.btn-outline-secondary
//{
//  border-color: #bbb;
//}

//.subheader .subheader-holder .book
//{
//  display: flex;
//  justify-content: flex-start;
//  //width: 200px;
//  height: 100%;
//  align-items: center;
//}

.subheader .subheader-holder .books > .book
{
  margin-left: 15px;
  margin-right: 15px;
}

.documentation-body .subheader .subheader-holder
{
  //margin-left: 0px;
  //margin-right: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.documentation-body .subheader .subheader-holder .btn-group
{
  margin-left: 0px;
  margin-right: 0px;
}

.documentation-body .documentation-sidebar ul li[data-level] > a
{
  color: #777;
}

.documentation-body .documentation-sidebar ul li[data-level='0'] > a
{
  color: #333;
}

.documentation-body .documentation-sidebar ul li > a:hover
{
  background: unset;
  border: unset;
  box-shadow: unset;
  text-shadow: unset;
}

.documentation-body .card-header
{
  font-weight: 600;
}

.documentation-body .card.card-bordered
{
  border: 1px #ddd solid;
}

// DATASTORE CONFIG

// property
.row.documentation table.datastore-config tbody tr td:nth-child(1)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// type
.row.documentation table.datastore-config tbody tr td:nth-child(2)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// default
.row.documentation table.datastore-config tbody tr td:nth-child(3)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// description
.row.documentation table.datastore-config tbody tr td:nth-child(5)
{
  width: 100%;
}


// PROPERTIES

// property
.row.documentation table.properties tbody tr td:nth-child(1)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// type
.row.documentation table.properties tbody tr td:nth-child(2)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// required
.row.documentation table.properties tbody tr td:nth-child(3)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// default
.row.documentation table.properties tbody tr td:nth-child(4)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// description
.row.documentation table.properties tbody tr td:nth-child(6)
{
  width: 100%;
}


// API SCHEMA

.row.documentation .api-schema > h1
{

}

.row.documentation .api-schema > h2
{

}

.row.documentation .api-schema .api-method
{
  padding-left: 36px;
  padding-top: 10px;
  padding-bottom: 30px;
}

//.row.documentation .api-schema .api-method:not(:first-child)
//{
//  margin-top: 30px;
//}

.row.documentation .api-schema .api-method > .api-method-description
{
  margin-top: 15px;
  margin-left: -36px;

  font-weight: 600;
}

.row.documentation .api-schema .api-method > .api-method-description > i
{
  margin-right: 15px;
}

.row.documentation .api-schema .api-method > .api-method-invocation
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 1.1em;

  padding-top: 10px;
  padding-bottom: 4px;

  word-wrap: break-word;
  word-break: break-word;
}

.row.documentation .api-schema .api-method > .api-method-parameters
{
  margin-top: 15px;

}

.row.documentation .api-schema .api-method > .api-method-response
{

}

.row.documentation .api-schema .api-method-label
{
  color: red !important;
}

.row.documentation .api-schema .api-method-body
{

}

.row.documentation .api-schema .api-method-body table.api-method-parameters-table
{
  margin-bottom: 30px;
}

// name
.row.documentation .api-schema .api-method-body table.api-method-parameters-table tbody tr td:nth-child(1)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// required
.row.documentation .api-schema .api-method-body table.api-method-parameters-table tbody tr td:nth-child(2)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// type
.row.documentation .api-schema .api-method-body table.api-method-parameters-table tbody tr td:nth-child(3)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// description
.row.documentation .api-schema .api-method-body table.api-method-parameters-table tbody tr td:nth-child(4)
{
  width: 100%;
}

.row.documentation .api-schema .api-kind {
  margin-bottom: 30px;
  margin-top: -30px;
}

.row.documentation .api-schema nav.api-breadcrumbs ol.breadcrumb {
  background-color: #f5f5f5;
  padding-bottom: 6px;
  margin-bottom: 30px;
  border: 1px #ddd solid;
}

.row.documentation .api-schema .api-toc {
  min-width: 300px;
  margin-left: 30px;
  margin-bottom: 30px;
}
.row.documentation .api-schema .api-toc dl {
  display: grid;
  grid-template-columns: 50% auto;
  margin-bottom: 0px;
}
.row.documentation .api-schema .api-toc dt {
  grid-column: 1;
}
.row.documentation .api-schema .api-toc dd {
  grid-column: 2;
}
.row.documentation .api-schema .api-toc ul {
  margin-top: 0px;
  margin-bottom: 0px;
}
.row.documentation .api-schema .api-toc ul li {
  font-size: 1em;
  margin-bottom: 6px;
}

.row.documentation .api-schema ul.api-methods,
.row.documentation .api-schema ul.api-datastores,
.row.documentation .api-schema ul.api-objects,
.row.documentation ul.api-references,
ul.ul-2-columns
{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.row.documentation .api-schema ul.api-methods > li,
.row.documentation .api-schema ul.api-datastores > li,
.row.documentation .api-schema ul.api-objects > li,
.row.documentation ul.api-references > li,
ul.ul-2-columns > li
{
  margin-bottom: 2px;
}

.row.documentation .api-schema table.api-properties-table
{
  margin-bottom: 30px;
}

// property
.row.documentation .api-schema table.api-properties-table tbody tr td:nth-child(1)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// type
.row.documentation .api-schema table.api-properties-table tbody tr td:nth-child(2)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// default
.row.documentation .api-schema table.api-properties-table tbody tr td:nth-child(3)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// description
.row.documentation .api-schema table.api-properties-table tbody tr td:nth-child(5)
{
  width: 100%;
}





// FIELD OPTIONS

// option
.row.documentation table.table.table-field-options tbody tr td:nth-child(1)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// type
.row.documentation table.table.table-field-options tbody tr td:nth-child(2)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// default
.row.documentation table.table.table-field-options tbody tr td:nth-child(3)
{
  color: #226baa;
  font-weight: 600;
  font-family: "Courier New";
  font-size: 0.95em;
}

// description
.row.documentation table.table.table-field-options tbody tr td:nth-child(4)
{
  width: 100%;
}